'use client';

import HideOnScroll from '@/components/HideOnScroll';
import PageTicket from '@/components/PageTicket';
import Box from '@/components/ui/Box';
import Container from '@/components/ui/Container';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { CATEGORIES_FRAGMENT, PROGRAM_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { useColorScheme } from '@/lib/parsers/common';
import useScrollPercentage from '@/lib/utils/useScrollPercentage';
import { createFragmentParser } from '@liquorice/gql-utils';
import { firstNonNullable } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { EventEntryFragment } from '__generated__/graphql';
import * as styles from './EventLayout.css';

const EventLayoutBanner = (props: EventEntryFragment) => {
  const { programCategory, status } = props;
  const card = getFragmentData(EVENT_CARD_FRAGMENT, props);
  const getCategory = createFragmentParser(CATEGORIES_FRAGMENT, (data) => data);
  const category = getCategory(firstNonNullable(programCategory));
  const parseProgram = createFragmentParser(PROGRAM_CATEGORY_FRAGMENT, (data) => data);
  const program =
    category?.__typename === 'programCategory_Category' ? parseProgram(category) : undefined;

  const disabled = status === 'expired';
  const pos = useScrollPercentage();

  const { colorScheme: colorSet, oppositeScheme } = useColorScheme(program?.colorScheme);

  return (
    <HideOnScroll direction="up" in={25 < pos && pos < 75}>
      <Box colorSet={!disabled ? colorSet : 'notifyRed'} paper className={styles.banner}>
        <Container cx={{ mY: '2xs' }}>
          <Grid rowGutter>
            <Grid.Col lg={7} cx={{ display: 'flex', alignItems: 'center' }}>
              <Txt variant="h6" cx={{ mB: 'none' }}>
                {card.entryTitle}
              </Txt>
            </Grid.Col>
            <Grid.Col offsetLg={1} lg={4}>
              <PageTicket
                TicketBtnProps={{ size: 'normal', color: !disabled ? oppositeScheme : 'error' }}
              />
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
    </HideOnScroll>
  );
};

export default EventLayoutBanner;
