import { getFragmentData } from '__generated__';
import { BlocksFragment } from '__generated__/graphql';
import { ACCORDION_BLOCK_FRAGMENT } from './BlockComponents/AccordionBlock';
import { EVENT_TYPE_BLOCK_FRAGMENT } from './BlockComponents/CategoryTypeBlock';
import { DIVIDER_BLOCK_FRAGMENT } from './BlockComponents/DividerBlock';
import { ENTRY_CARDS_BLOCK_FRAGMENT } from './BlockComponents/EntryCardsBlock';
import { EVENT_CREDITS_BLOCK_FRAGMENT } from './BlockComponents/EventCreditsBlock';
import { EVENT_INDEX_BLOCK_FRAGMENT } from './BlockComponents/EventIndexBlock';
import { EVENT_SEARCH_BLOCK_FRAGMENT } from './BlockComponents/EventSearchBlock';
import { EXPANDING_CARDS_BLOCK_FRAGMENT } from './BlockComponents/ExpandingCardsBlock';
import { FEATURED_EVENT_BLOCK_FRAGMENT } from './BlockComponents/FeaturedEventBlock';
import { FILE_DOWNLOAD_BLOCK_FRAGMENT } from './BlockComponents/FileDownloadBlock';
import { FORM_BLOCK_FRAGMENT } from './BlockComponents/FormBlock';
import { IMAGE_AND_TEXT_BLOCK_FRAGMENT } from './BlockComponents/ImageAndTextBlock';
import { IMAGE_BLOCK_FRAGMENT } from './BlockComponents/ImageBlock';
import { IMAGE_CAROUSEL_BLOCK_FRAGMENT } from './BlockComponents/ImageCarouselBlock';
import { LOGO_AND_TEXT_BLOCK_FRAGMENT } from './BlockComponents/LogoAndTextBlock';
import { OVERVIEW_CARDS_BLOCK_FRAGMENT } from './BlockComponents/OverviewCardsBlock';
import { QUOTE_CAROUSEL_BLOCK_FRAGMENT } from './BlockComponents/QuoteCarouselBlock';
import { RICH_TEXT_BLOCK_FRAGMENT } from './BlockComponents/RichTextBlock';
import { VIDEO_BLOCK_FRAGMENT } from './BlockComponents/VideoBlock';

type Blocks = BlocksFragment | null;

export const useBlocks = (data: Blocks) => {
  if (!data) return null;

  switch (data.__typename) {
    case 'blocks_richText_BlockType':
      return getFragmentData(RICH_TEXT_BLOCK_FRAGMENT, data);
    case 'blocks_accordion_BlockType':
      return getFragmentData(ACCORDION_BLOCK_FRAGMENT, data);
    case 'blocks_expandingCards_BlockType':
      return getFragmentData(EXPANDING_CARDS_BLOCK_FRAGMENT, data);
    case 'blocks_overviewCards_BlockType':
      return getFragmentData(OVERVIEW_CARDS_BLOCK_FRAGMENT, data);
    case 'blocks_entryCards_BlockType':
      return getFragmentData(ENTRY_CARDS_BLOCK_FRAGMENT, data);
    case 'blocks_eventIndex_BlockType':
      return getFragmentData(EVENT_INDEX_BLOCK_FRAGMENT, data);
    case 'blocks_categoryType_BlockType':
      return getFragmentData(EVENT_TYPE_BLOCK_FRAGMENT, data);
    case 'blocks_eventSearch_BlockType':
      return getFragmentData(EVENT_SEARCH_BLOCK_FRAGMENT, data);
    case 'blocks_eventCredits_BlockType':
      return getFragmentData(EVENT_CREDITS_BLOCK_FRAGMENT, data);
    case 'blocks_featuredEvent_BlockType':
      return getFragmentData(FEATURED_EVENT_BLOCK_FRAGMENT, data);
    case 'blocks_imageAndText_BlockType':
      return getFragmentData(IMAGE_AND_TEXT_BLOCK_FRAGMENT, data);
    case 'blocks_logoAndText_BlockType':
      return getFragmentData(LOGO_AND_TEXT_BLOCK_FRAGMENT, data);
    case 'blocks_imageCarousel_BlockType':
      return getFragmentData(IMAGE_CAROUSEL_BLOCK_FRAGMENT, data);
    case 'blocks_image_BlockType':
      return getFragmentData(IMAGE_BLOCK_FRAGMENT, data);
    case 'blocks_video_BlockType':
      return getFragmentData(VIDEO_BLOCK_FRAGMENT, data);
    case 'blocks_quoteCarousel_BlockType':
      return getFragmentData(QUOTE_CAROUSEL_BLOCK_FRAGMENT, data);
    case 'blocks_fileDownload_BlockType':
      return getFragmentData(FILE_DOWNLOAD_BLOCK_FRAGMENT, data);
    case 'blocks_divider_BlockType':
      return getFragmentData(DIVIDER_BLOCK_FRAGMENT, data);
    case 'blocks_form_BlockType':
      return getFragmentData(FORM_BLOCK_FRAGMENT, data);
  }
};

export type BlocksReturnType = ReturnType<typeof useBlocks>;
