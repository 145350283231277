'use client';

import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import { createBlockMeta } from '@/lib/parsers/blocks';
import { PageExpandableEntryFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import Box from '../ui/Box';
import UnstyledAccordion from '../UnstyledAccordion';
import AnchorTreeNavigationAccordionItem from './AnchorTreeNavigation.AccordionItem';

const AnchorTreeNavigation = (props: PageExpandableEntryFragment) => {
  const t = useTranslations('actions');

  if (!props.blocks) return null;

  const blocks = createBlockMeta(props.blocks);

  const topLink = (
    <Box cx={{ p: 'xs', bgcolor: 'primaryTintLighter' }}>
      <Btn variant="text" color="primary" fullWidth weight="bold" style={{ whiteSpace: 'wrap' }}>
        {t('jumpTo')}
      </Btn>
    </Box>
  );

  return (
    <Grid cx={{ mY: '3xl' }}>
      <Grid.Col offsetMd={3} md={9}>
        {topLink}
        <UnstyledAccordion>
          {blocks.map((block) => {
            return (
              <AnchorTreeNavigationAccordionItem
                key={block.meta?.index}
                {...{ index: block.meta?.index, maskedBlock: block }}
              />
            );
          })}
        </UnstyledAccordion>
      </Grid.Col>
    </Grid>
  );
};

export default AnchorTreeNavigation;
