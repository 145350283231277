'use client';

import UnstyledAccordion from '@/components/UnstyledAccordion';
import { parseUri } from '@liquorice/utils';
import * as styles from './PagesTreeNavigation.css';

type Item = {
  __typename: string;
  title?: string | null;
  uri?: string | null;
  id?: string | null;
  level?: number | null;
  children?: Array<Item> | null;
};

type PagesTreeNavigationAccordionItemProps = {
  item: Item;
  index: number;
  borderTop?: boolean;
  borderBottom?: boolean;
};

const PagesTreeNavigationAccordionItem = ({
  item,
  index,
  borderTop,
  borderBottom,
}: PagesTreeNavigationAccordionItemProps) => {
  const { title, uri, level, children } = item;

  return (
    <UnstyledAccordion BoxProps={{ cx: { width: 'full' } }}>
      <UnstyledAccordion.Item
        index={index}
        className={styles.item({
          borderTop: borderTop ?? true,
          borderBottom: borderBottom ?? true,
        })}>
        <UnstyledAccordion.ItemHandle
          index={index}
          disableHandleLink={!!children?.length}
          cx={{ p: 'xs' }}
          justifyContent="start"
          columnGap="xs"
          style={{ paddingLeft: `${level ?? 0 * 0.5}rem` }}>
          {!!children?.length && (
            <UnstyledAccordion.ItemCaret
              index={index}
              openIcon="chevronDown"
              closeIcon="chevronUp"
              enableToggle
            />
          )}
          <UnstyledAccordion.ItemLabel
            variant="text"
            color="primary"
            as="a"
            href={parseUri(uri)}
            cx={{ textAlign: 'left' }}>
            {title}
          </UnstyledAccordion.ItemLabel>
        </UnstyledAccordion.ItemHandle>
        <UnstyledAccordion.ItemBody
          index={index}
          disablePadding
          cx={{ width: 'full' }}
          InnerProps={{ style: { rowGap: 0 } }}>
          {children?.map((child, childIndex) => {
            return (
              <PagesTreeNavigationAccordionItem
                key={child.id}
                {...{
                  item: child,
                  index: childIndex,
                  borderTop: true,
                  borderBottom: childIndex !== children.length - 1,
                }}
              />
            );
          })}
        </UnstyledAccordion.ItemBody>
      </UnstyledAccordion.Item>
    </UnstyledAccordion>
  );
};

export default PagesTreeNavigationAccordionItem;
