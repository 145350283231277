'use client';

import {
  useDepreciatedEntryIndexFilter,
  useEntryIndexFilterItem,
} from '@/components/EntryIndex/useEntryIndexFilter_DEP';
import Box from '@/components/ui/Box';
import Flex, { FlexProps } from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import { ElementTree, ElementTreeItem } from '@/lib/parsers/common/parseElementList';
import UnstyledAccordion from '../UnstyledAccordion';
import * as styles from './EntryIndex.css';
import { FilterSliceState } from './entryIndexTypes';

export type FilterItemProps = {
  item: ElementTreeItem;
};

const FilterItem = ({ item }: FilterItemProps) => {
  const { active, toggle } = useEntryIndexFilterItem(item);

  const idStr = `filter-input-${item.id}`;

  return (
    <Flex as="li">
      <Txt
        as="label"
        variant="small"
        id={`${idStr}-label`}
        onChange={() => toggle()}
        className={styles.inputText}>
        <input
          className={styles.inputCheckbox}
          id={item.id}
          type="checkbox"
          checked={active}
          onChange={() => toggle()}
        />{' '}
        {item.title}
      </Txt>
      {item.children && (
        <FilterList
          items={item.children}
          cx={{
            paddingLeft: 'sm',
          }}
        />
      )}
    </Flex>
  );
};

type FilterListProps = FlexProps<
  'ul',
  {
    items: ElementTree;
  }
>;

/**
 * Recursively display list of filters
 */
const FilterList = ({ items, ...props }: FilterListProps) => {
  return (
    <Flex as="ul" cx={{ rowGap: 'xs' }} {...props}>
      {items.map((item) => (
        <FilterItem key={item.id} item={item} />
      ))}
    </Flex>
  );
};

export type EntryIndexFilterProps = {
  filterId: string;
};

const EntryIndexFilter = ({ filterId }: EntryIndexFilterProps) => {
  const { itemTree } = useDepreciatedEntryIndexFilter(filterId);

  return itemTree.length ? (
    <Box className={styles.indexFilter} as="fieldset" id={filterId}>
      <FilterList items={itemTree} />
    </Box>
  ) : null;
};

export type EntryIndexFilterAccordionProps = {
  filters: FilterSliceState[];
};

const EntryIndexFilterAccordion = ({ filters }: EntryIndexFilterAccordionProps) => {
  const standardFilters = filters;
  // const standardFilters = filters.filter(({ type }) => ['category', 'entry'].includes(type));

  return (
    <UnstyledAccordion
      items={
        standardFilters.map((filter, index) => {
          return {
            index,
            className: styles.unstyledAccordionItem,
            LabelProps: {
              children: (
                <Txt as="span" variant="medium">
                  {filter.label}
                </Txt>
              ),
            },
            BodyProps:{index,children: <EntryIndexFilter filterId={filter.id} />}
          };
        }) ?? []
      }
    />
  );
};

export default EntryIndexFilterAccordion;
