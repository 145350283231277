'use client';

import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { useTranslations } from 'next-intl';
import React from 'react';
import { selectIsFiltered } from './entryIndexSelectors';

export default function useEntryIndexClearButton(): React.ComponentProps<'button'> {
  const resetFilters = useEntryIndex((s) => s.resetFilters);
  const isFiltered = useEntryIndex(selectIsFiltered);
  const loading = useEntryIndex((s) => s.loading);
  const t = useTranslations('entryIndex');

  const handleReset = () => {
    resetFilters();
  };

  return {
    disabled: loading || !isFiltered,
    hidden: !isFiltered,
    onClick: handleReset,
    type: 'reset',
    children: t('clear'),
  };
}
