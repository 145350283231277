'use client';

import { selectActiveFilters } from '@/components/EntryIndex/entryIndexSelectors';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import useEntryIndexClearButton from '@/components/EntryIndex/useEntryIndexClearButton';
import Btn, { BtnProps } from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import { Chip } from '@mui/material';
import Icon from '../Icon';

/**
 * Display all active filters as chips in a single list
 */
const EntryIndexFilterChips = () => {
  const filterRemove = useEntryIndex((s) => s.filterRemove);
  const filterItems = useEntryIndex(selectActiveFilters);
  const clearButtonProps = useEntryIndexClearButton();

  return filterItems.length ? (
    <Flex spacing="3xs" wrap direction="row">
      {filterItems.map((filter) =>
        filter ? (
          <Chip
            key={filter.id}
            size="small"
            variant="outlined"
            label={filter.title}
            sx={{
              borderRadius: 0,
              backgroundColor: 'transparent',
              border: '1px solid currentColor',
            }}
            deleteIcon={<Icon name="close" />}
            onDelete={() => filterRemove(filter.collectionId, filter.id)}
          />
        ) : null
      )}
      <Btn size="tiny" variant="text" underline="always" {...(clearButtonProps as BtnProps)} />
    </Flex>
  ) : null;
};

export default EntryIndexFilterChips;
