'use client';

import UnstyledAccordion from '@/components/UnstyledAccordion';
import Grid from '@/components/ui/Grid';
import { SIBLING_BRANCH_FRAGMENT } from '@/gql/fragments/entries.gql';
import { withFragment } from '@liquorice/gql-utils';
import { EntryBaseFragment } from '__generated__/graphql';
import Breadcrumbs from '../Breadcrumbs';
import Box from '../ui/Box';
import PagesTreeNavigationAccordionItem from './PagesTreeNavigation.AccordionItem';
import * as styles from './PagesTreeNavigation.css';

const PagesTreeNavigation = withFragment(
  SIBLING_BRANCH_FRAGMENT,
  (data, props: EntryBaseFragment) => {
    if (!data) return;

    const topLink = (
      <Box cx={{ p: 'xs', bgcolor: 'primaryTintLighter' }}>
        <Breadcrumbs
          data={props}
          CtaButtonProps={{ whiteSpace: 'normal' }}
          CtaButtonClass={styles.breadcrumb}
        />
      </Box>
    );

    const siblings = data.children.filter((item) => item.id !== props.id);

    return (
      <Grid cx={{ mT: '3xl', mB: { md: '3xl' } }}>
        <Grid.Col offsetLg={3} lg={9}>
          {topLink}
          <UnstyledAccordion>
            {siblings.map((item, index) => {
              return (
                item && <PagesTreeNavigationAccordionItem key={item.id} {...{ item, index }} />
              );
            })}
          </UnstyledAccordion>
        </Grid.Col>
      </Grid>
    );
  }
);

export default PagesTreeNavigation;
