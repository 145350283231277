'use client';

import EntryIndexFilterAccordion from '@/components/EntryIndex/EntryIndex.Filter';
import EntryIndexFilterChips from '@/components/EntryIndex/EntryIndex.FilterChips';
import EntryIndexSearchForm from '@/components/EntryIndex/EntryIndex.SearchForm';
import EntryIndexSummary from '@/components/EntryIndex/EntryIndex.Summary';
import EntryIndexTitle from '@/components/EntryIndex/EntryIndex.Title';
import { FILTERS_BREAKPOINT } from '@/components/EntryIndex/entryIndexConstants';
import { FilterSliceState } from '@/components/EntryIndex/entryIndexTypes';
import IconButton from '@/components/IconButton';
import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import { useBreakpointMax } from '@/theme';
import { Dialog } from '@mui/material';
import { useTranslations } from 'next-intl';
import React from 'react';

type EntryIndexFiltersProps = {
  filtersConfig?: FilterSliceState[];
};

const EntryIndexFilters = ({ filtersConfig = [] }: EntryIndexFiltersProps) => {
  const showDialog = useBreakpointMax(FILTERS_BREAKPOINT);
  const t = useTranslations('entryIndex');

  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen(!open);

  const inner = (
    <Flex rowGap="gutter">
      <EntryIndexFilterChips />
      <EntryIndexFilterAccordion filters={filtersConfig} />
      <EntryIndexSearchForm />
      {/* <EntryIndexFilterButtons /> */}
    </Flex>
  );

  return !showDialog ? (
    inner
  ) : (
    <Flex direction="column" cx={{ rowGap: 'sm', mB: 'md' }}>
      <Btn
        onClick={toggleOpen}
        fullWidth
        rounded="small"
        size="large"
        variant="outlined"
        cx={{
          display: { xs: 'block', sm: 'none' },
          marginBottom: 'gutter',
        }}>
        {t('filter')}
      </Btn>
      <Dialog open={open} fullWidth>
        <Flex direction="column" cx={{ padding: 'sm', rowGap: 'lg' }}>
          <Flex direction="row" justifyContent="between">
            <EntryIndexTitle variant="h4" />
            <IconButton icon="close" onClick={toggleOpen} variant="text" />
          </Flex>
          {inner}
        </Flex>
      </Dialog>
      <EntryIndexFilterChips />
      <EntryIndexSummary />
    </Flex>
  );
};

export default EntryIndexFilters;
