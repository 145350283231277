'use client';

import { BLOCKS_FRAGMENT } from '@/gql/fragments/blocks.gql';
import { MaskedBlockProps } from '@/lib/parsers/blocks';
import { vars } from '@/theme/vars.css';
import { maybeGet, slugify } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { useBlocks } from '../Blocks/useBlocks';
import UnstyledAccordion from '../UnstyledAccordion';

export type AnchorTreeNavigationAccordionProps = {
  index?: number;
  maskedBlock: MaskedBlockProps<unknown>;
};

const AnchorTreeNavigationAccordionItem = ({
  index,
  maskedBlock,
}: AnchorTreeNavigationAccordionProps) => {
  const blocks = getFragmentData(BLOCKS_FRAGMENT, maskedBlock.data);
  const maybeBlockData = useBlocks(blocks);
  const maybeHeading = maybeGet(maybeBlockData, 'heading');

  if (index === undefined) return null;

  const handleClick = () => {
    if (typeof window !== 'undefined' && maybeHeading) {
      window.location.hash = slugify(maybeHeading);
    }
  };

  if (maybeHeading) {
    return (
      <UnstyledAccordion.Item
        {...{ index, style: { borderColor: vars.palette.colors.primaryTintLight } }}>
        <UnstyledAccordion.ItemHandle
          {...{
            cx: { p: 'xs' },
            justifyContent: 'start',
            columnGap: '4xs',
            align: 'left',
          }}>
          <UnstyledAccordion.ItemLabel
            {...{
              onClick: handleClick,
              cx: { textAlign: 'left' },
              variant: 'text',
              color: 'primary',
            }}>
            {maybeHeading}
          </UnstyledAccordion.ItemLabel>
        </UnstyledAccordion.ItemHandle>
      </UnstyledAccordion.Item>
    );
  }
};

export default AnchorTreeNavigationAccordionItem;
