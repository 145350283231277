'use client';

import AnchorTreeNavigation from '@/components/AnchorTreeNavigation';
import Sidebar from '@/components/Sidebar';
import { PageExpandableEntryFragment } from '__generated__/graphql';

const ExpandableLayoutSidebar = (props: PageExpandableEntryFragment) => {
  return (
    <Sidebar floating>
      <AnchorTreeNavigation {...props} />
    </Sidebar>
  );
};

export default ExpandableLayoutSidebar;
