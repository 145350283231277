'use client';

import { mergePropsClassName } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useRef } from 'react';
import { useNavbarActions } from '../NavBar/useNavbarState';
import Box, { BoxProps } from '../ui/Box';
import * as styles from './Sidebar.css';

export type SidebarProps<T extends TagName> = BoxProps<
  T,
  {
    floating?: boolean;
    BodyProps?: BoxProps;
  }
>;

const Sidebar = <T extends TagName>({
  floating,
  children,
  BodyProps,
  ...props
}: SidebarProps<T>) => {
  /** Use the navbar height for the scroll offset */
  const scrollOffset = useNavbarActions((s) => s.height);
  const navBarPosition = useNavbarActions((s) => s.navBarPosition);
  const wrapRef = useRef<HTMLDivElement>(null);

  const cssVars = assignInlineVars({
    [styles.topOffset]: navBarPosition ? `0px` : `${scrollOffset}px`,
  });

  return (
    <Box
      id="sidebar"
      ref={wrapRef}
      style={cssVars}
      {...mergePropsClassName(props, styles.root({ floating }))}>
      {children && <Box {...mergePropsClassName(BodyProps, styles.body)}>{children}</Box>}
    </Box>
  );
};

export default Sidebar;
