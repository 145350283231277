'use client';

import { Pagination, PaginationProps } from '@/components/ui';
import React from 'react';
import { useEntryIndexPagination } from './useEntryIndexPagination';

export type EntryIndexPaginationProps = PaginationProps;

const EntryIndexPagination = ({ onChange, sx, ...props }: EntryIndexPaginationProps) => {
  const { goToPage, page, totalItems: count } = useEntryIndexPagination();

  const handleChange = (ev: React.ChangeEvent<unknown>, pageTo: number) => {
    goToPage(pageTo);

    onChange && onChange(ev, pageTo);
  };

  return count > 1 ? (
    <Pagination
      onChange={handleChange}
      {...{
        page,
        count,
        sx: {
          display: 'flex',
          justifyContent: 'center',
          marginY: 6,
          ...sx,
        },
        ...props,
      }}
    />
  ) : (
    <></>
  );
};

export default EntryIndexPagination;
