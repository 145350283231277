'use client';

import Btn from '@/components/ui/Btn';
import Txt from '@/components/ui/Txt';
import { useTranslations } from 'next-intl';
import Container from '../ui/Container';

const NotFound = () => {
  const t = useTranslations('search');
  return (
    <Container cx={{ pY: '5xl' }}>
      <Txt as="h3" variant="h3">
        {t('notFound')}
      </Txt>
      <Txt as="p" variant="medium">
        {t('notFoundSuggestion')}
      </Txt>
      <Btn href="/search" size="large" cx={{ mT: 'sm' }}>
        {t('notFoundAction')}
      </Btn>
    </Container>
  );
};

export default NotFound;
