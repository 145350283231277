'use client';

import { useTranslations } from 'next-intl';
import Txt, { TxtProps } from '../ui/Txt';

type EntryIndexTitleProps = TxtProps<
  'span',
  {
    message?: string;
  }
>;

const EntryIndexTitle = ({ message: customMessage, ...props }: EntryIndexTitleProps) => {
  const t = useTranslations('entryIndex');

  const message = customMessage || t('filter');

  return (
    <Txt as="span" {...props}>
      {message}
    </Txt>
  );
};

export default EntryIndexTitle;
