'use client';

import { selectIsFiltered } from '@/components/EntryIndex/entryIndexSelectors';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { useTranslations } from 'next-intl';
import Txt, { TxtProps } from '../ui/Txt';

const EntryIndexSummary = (props: TxtProps) => {
  const total = useEntryIndex((s) => s.totalItems);
  const t = useTranslations('entryIndex');
  const isFiltered = useEntryIndex(selectIsFiltered);
  const isLoading = useEntryIndex((s) => s.loading);
  const initialLoaded = useEntryIndex((s) => s.initialLoaded);
  const showLoading = isLoading || !initialLoaded;
  const message = showLoading
    ? t('itemsLoading')
    : isFiltered
    ? t('itemsFound', { count: total })
    : t('allResults', { count: total });

  return (
    <Txt color="neutralDark" as="span" variant="medium" {...props}>
      {message}
    </Txt>
  );
};

export default EntryIndexSummary;
